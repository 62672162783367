import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getMoleculesNames, getMoleculeRoutes } from '../../helpers/pickMoleculesInfos';
import { Box, BoxTitle, Step } from './FormStyles';

const BoxOption = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  color: #045F61;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  cursor: pointer;
  text-transform: uppercase;
  &.active {
    background-color: #045F61;
    color: #FFFFFF;
  }
`;

const GlobalBox = styled.div`
  border-radius: 8px;
  margin: 15px 0;
  overflow: hidden;
  box-shadow: 0 0 8px #679d9f24;
`;

const RoutesBox = styled.div`
  width: 100%;
  background-color: #418688;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  cursor: pointer;
  `;

const RouteOption = styled.span`
  margin: 0 5px;
  background-color: #FFFFFF;
  color: #045F61;
  width: 100%;
  max-width: 50%;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  &.active {
    background-color: #045F61;
    color: #FFFFFF;
  }
`;

const SelectBox = ({title, options, setSelected, setRoute, step, context}) => {

  const [molecules, setMolecules] = useState([]);
  const [availableRoutes, setAvailableRoutes] = useState([]);

  const [moleculeSelected, setMoleculeSelected] = useState(null);
  const [routeSelected, setRouteSelected] = useState(null);
  
  useEffect(() => {
    if (moleculeSelected) {
      let routes = getMoleculeRoutes(moleculeSelected, options, context);
      setAvailableRoutes(routes);
    }

  } , [molecules, moleculeSelected]);

  useEffect(() => {
    let moleculesNames = getMoleculesNames(options, context);
    let molecules = moleculesNames.map(molecule => {
      return {name: molecule, active: false}
    });
    setMolecules(molecules);
  }, [options])
  
  const selectMolecule = (molecule) => {
    setSelected(molecule);
    let newMolecules = molecules.map(m => {
      if (m.name === molecule.name) {
        m.active = true;
      } else {
        m.active = false;
      }
      return m;
    });
    setMolecules(newMolecules);
    setRouteSelected(null);
    setMoleculeSelected(molecule.name);
  }

  const selectRoute = (route) => {
    setRoute(route);
    setRouteSelected(route);
  }

  return (
    <Box>
      <BoxTitle>
        <Step>{step}</Step>
        {title}
      </BoxTitle>
      {
        molecules.map((molecule, key) => {
          return (
            <GlobalBox key={key}>
            <BoxOption 
              className={molecule.active ? 'active' : ''}
              onClick={() => selectMolecule(molecule)}>
              {molecule.name}
            </BoxOption>
              {
                molecule.active && (
                  <RoutesBox>
                    {
                      availableRoutes.map((route, key) => {
                        return (
                          <RouteOption 
                            key={key}
                            className={routeSelected === route ? 'active' : ''}
                            onClick={() => selectRoute(route)}>
                              {route}
                          </RouteOption>
                        )
                      })
                    }
                  </RoutesBox>
                )
              }        
            </GlobalBox>
          )
        })
      }
    </Box>
  )
}

export default SelectBox