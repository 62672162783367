import React from 'react';
import styled from 'styled-components';

const GlobalButton = styled.button`
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border-radius: 8px;
  width: 97%;
  height: 54px;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 18px;
  appearance: none;
  border: none;
  cursor: pointer;
  @media (min-width: 900px) {
    width: 100%;
  }
`;

const Button = ({label, bgColor, color}) => {
  return (
    <GlobalButton 
      bgColor = {bgColor}
      color = {color}
      >{ label }
    </GlobalButton>
  )
}
 
export default Button