import React from 'react';
import {
  Outlet,
} from "react-router-dom";
import NavBar from '../components/Nav';
import Header from '../components/Header';
import styled from 'styled-components';
import bg from '../images/bg.jpg';

const AppWrapper = styled.div`
  background-image: url('${bg}');
  background-size: cover;
  height: 100vh;
`;

const Wrapper = styled.div`
  margin: 0;
  height: 100%;
`;

const Content = styled.div`
  @media (min-width: 900px) {
    margin: 80px 0 0 0;
  }
`;

const Layout = () => {
  return (
    <AppWrapper>
      <Wrapper>
        <Header/>
        <NavBar />
        <Content>
          <Outlet />
        </Content>
      </Wrapper>
    </AppWrapper>
  )
}

export default Layout