import React, { useEffect, useState } from 'react';
import { Box, BoxTitle, BoxInput, BoxContent, BoxUnit, Step } from './FormStyles';
import {useRef} from 'react';

const InputBox = ({title, setDosage, focus, unit, step}) => {

  const ref = useRef(null);

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus])

  const [value, setValue] = useState('');

  const handleChange = (e) => {
    const result = e.target.value.replace(/\D/g, '');
    setValue(result);
    setDosage(result);
  }
  

  return (
    <Box>
      <BoxTitle>
        <Step>{step}</Step>
        {title}
      </BoxTitle>
      <BoxContent>
        <BoxInput
          pattern="[0-9]*" 
          type="text"
          value={value}
          maxLength={4}
          onChange={(e) => handleChange(e)}
          ref={ref}
        />
        {
          unit &&
          <BoxUnit>{unit}</BoxUnit>
        }
      </BoxContent>
    </Box>
  )
}

export default InputBox