import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../images/logo.svg';

const HeaderWrapper = styled.header`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 25px;
  @media (min-width: 900px) {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 9;
    justify-content: end;
    padding: 15px 25px;
    box-shadow: 0 1px 6px #367e8014;
  }
  h1 {
    padding-left: 25px;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    color: rgb(12 128 131);
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Logo />
      <h1>Opioconvert</h1>
    </HeaderWrapper>
  )
}

export default Header