import React from 'react';
import styled from 'styled-components';

const SimpleBloc = styled.section`
  border-radius: 8px;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  padding: 10px 25px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: ${props => props.boxShadow};
  h3 {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
  }
  h4 {
    margin: 0;
    font-size: 26px;
    font-weight:500;
    text-transform: capitalize;
  }
  p {
    font-family: 'Roboto', sans-serif;
    margin: 10px 0 20px 0;
    &.big {
      font-size: 38px;
      font-weight: bold;
    }
    span.unit {
      font-size: 20px;
    }
  }
`;

const Bloc = ({bgColor, color, boxShadow, children}) => {
  let boxShadowValue = boxShadow ? '0 1px 6px #367e8014' : 'none';
  return (
    <SimpleBloc
      bgColor = {bgColor}
      color = {color}
      boxShadow = {boxShadowValue}
    >
      { children }
    </SimpleBloc>
  )
}

export default Bloc