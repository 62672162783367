const modalContents = () => {
  return (
    {
      offline: {
        title: {
          fr: "Connexion internet indisponible",
          en: "No internet connexion"
        },
        content: {
          fr: "Vérifez votre connexion puis réessayez.",
          en: "Please check your internet connection and try again."
        }
      }
    }
  )
}

export default modalContents;