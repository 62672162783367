import React, { useEffect } from "react";
import './App.css';
import {
  Routes,
  Route
} from "react-router-dom";
import { setVisited, hasVisited } from "./helpers/localStorage";
import { useNavigate } from "react-router";

import Layout from "./Layout/Layout";
import Calculator from "./Routes/Calculator";
import About from "./Routes/About";
import Settings from "./Routes/Settings";

import { OnlineStatusProvider } from "./hooks/useOnlineStatus";

function App() {

  const navigate = useNavigate();
  useEffect(() => {
    if (!hasVisited()) {
      setVisited();
      navigate("/about");
    }
  }, [navigate]);

  return (
    <OnlineStatusProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Calculator />} />
          <Route path="about" element={<About />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </OnlineStatusProvider>
  );
}

export default App;
