import React from 'react';
import LangSwitcher from '../components/LangSwitcher/LangSwitcher';
import Bloc from '../components/Blocs/Bloc';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ContentBloc } from '../styles/common';

const Settings = () => {

  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <ContentBloc>
        <Bloc
          bgColor='#E5F1F8'
          color='#0C2566'>
          <h3>{t('languages')}</h3>
          <LangSwitcher/>
        </Bloc>
        <Bloc
        bgColor='#E5F1F8'
        color='#0C2566'
        textSize='normal'>
          <h3>{t('mentions')}</h3>
          <p>{t('mentionsText')}</p>
        </Bloc>
      </ContentBloc>
      <ContentBloc>
        <Bloc
          bgColor='#E5F8F4'
          color='#045F61'
          textSize='normal'>
          <h3>{t('warningTitle')}</h3>
          <p dangerouslySetInnerHTML={{__html: t('warningsText')}}></p>
        </Bloc>
      </ContentBloc>
    </ContentWrapper>
  )
}

export default Settings