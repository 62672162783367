import styled from "styled-components";

export const Box = styled.section`
  border-radius: 8px;
  background-color: #E5F8F4;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

export const BoxTitle = styled.h3`
  color: #045F61;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  position: relative;
`;

export const Step = styled.span`
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 3px;
  background: rgb(4 95 97);
  font-size: 14px;
  position: absolute;
  left: 0;
`

export const BoxContent = styled.div`
  position: relative;
`;

export const BoxInput = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  border-radius: 8px;
  margin: 0 0 8px 0;
  border: none;
  font-size: 22px;
  box-shadow: 0 0 8px #679d9f24;
  outline-color: rgb(11 141 144);
  color: #055E61;
  text-align: center;
  font-weight: bold;
`;

export const BoxUnit = styled.span`
position: absolute;
    color: rgb(255 255 255);
    font-family: 'Roboto',sans-serif;
    right: 10px;
    top: 10px;
    background-color: rgb(4 95 97);
    padding: 4px 6px;
    border-radius: 4px;
`;