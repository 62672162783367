export function transformTranslations(apiTranslations) {
  const translations = {
    en: { translation: {} },
    fr: { translation: {} },
  };

  apiTranslations.forEach((item) => {
    translations.en.translation[item.identifier] = item.en;
    translations.fr.translation[item.identifier] = item.fr;
  });
  
  return translations;
}

