export const getMatchingConversionInfos = (molecules, moleculeIn, routeIn, moleculeOut, routeOut, dosage) => {
  const matchingConversionInfos = molecules.filter(molecule => molecule.fromMed === moleculeIn.name && molecule.toMed === moleculeOut.name && molecule.fromType === routeIn && molecule.toType === routeOut)
  console.log(matchingConversionInfos);
  if (matchingConversionInfos.length > 0) {
    const conversionInfo = matchingConversionInfos[0];
    const dosageResult = {
      conversionResult: Math.round(dosage * conversionInfo.rate),
      conversionDosage: dosage,
      ...conversionInfo
    }
    return dosageResult;
  }
}

export const getUnit = (molecules, moleculeIn) => {
  const matchingMolecule = molecules.filter(molecule => molecule.fromMed === moleculeIn.name);
  console.log(matchingMolecule);
  return matchingMolecule.length === undefined ? matchingMolecule.fromUnit : matchingMolecule[0].fromUnit;
}