import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SelectBox from '../components/Form/SelectBox';
import InputBox from '../components/Form/InputBox';
import Button from '../components/Buttons/Button';
import Bloc from '../components/Blocs/Bloc';
import Summary from '../components/Summary/Summary';
import Loader from '../components/Loader/Loader';
import Modal from '../components/Modal/Modal';
import modalContents from '../contents/ModalContents';
import { useTranslation } from 'react-i18next';
import { useOnlineStatus } from '../hooks/useOnlineStatus';

import { getMoleculesWithRates } from '../services/molecules';
import { 
  getMatchingConversionInfos,
  getUnit
} from '../helpers/converter';

const MainContent = styled.section`
  padding-bottom: 100px;
`;

const ContentWrapper = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
  }
  .debug {
    position: fixed;
    background-color: red;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 999;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  @media (min-width: 900px) {
    flex-direction: row;
    width: 100%;
    padding: 20px 0;
    &.sided {
      width: 75%;
      > div {
        width: 33.33%;
        pointer-events: none!important;
        opacity: 0.5;
      }
    }
  }
`;

const FormCol = styled.div`
  @media (min-width: 900px) {
    width: 25%;
    margin: 0 15px;
    opacity: 0.6;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

const Result = styled.div`
padding: 20px 25px;
  @media (min-width: 900px) {
    width: 25%;
    padding: 20px 0;
  }
`;

const ButtonContent = styled.div`
  margin: 0 15px;
  @media (max-width: 900px) {
    background-color: #fff;
    border-radius: 8px;
    height: 72px;
    display: flex;
    align-items: center;
    margin: 0 25px;
    padding: 0 5px;
    justify-content: space-around;
    box-shadow: 0 0 1px 0 rgb(4 95 97 / 19%);
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  @media (max-width: 900px) {
    position: fixed;
    left: 0;
    bottom: 18px;
    width: 100%;
    z-index: 110;
  }
`;

const BlocsWrapper = styled.div`
  display: flex;
  margin: 30px 0;
  flex-direction: column;
  @media (min-width: 900px) {
    margin: 0 15px;
  }
`;

const Calculator = () => { 

  const modal = useRef(null);
  const modalWarning = useRef(null);

  const isOnline = useOnlineStatus();
  const { t, i18n } = useTranslation(); 
  const currentLang = i18n.languages[0];

  const [molecules, setMolecules] = useState(null);
  const [moleculesListIn, setMoleculesListIn] = useState(null);
  const [moleculesListOut, setMoleculesListOut] = useState([]);
  const [moleculeIn, setMoleculeIn] = useState(null);
  const [moleculeOut, setMoleculeOut] = useState(null);
  const [stepper, setStepper] = useState(1);
  const [routeIn, setRouteIn] = useState(null);
  const [unit, setUnit] = useState(null);
  const [dosage, setDosage] = useState(null);
  const [dosageFocus, setDosageFocus] = useState(false);
  const [routeOut, setRouteOut] = useState(null);
  const [dosageType, setDosageType] = useState(null);
  const [result, setResult] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const [selection, setSelection] = useState();


  useEffect(() => {
    if (localStorage.getItem('molecules') === null) {
      getMolecules(); 
    } else {
      const moleculesFromStorage = JSON.parse(localStorage.getItem('molecules'));
      setMolecules(moleculesFromStorage);
      setMoleculesListIn(moleculesFromStorage);
      checkDataExpiration();
    }
    if (window.innerWidth < 900) {
      setIsMobile(true);
    }
    manageResize();
  }, [])

  useEffect(() => {
    manageConnection();
  }, [isOnline])

  useEffect(() => {
    if (molecules !== null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [molecules])

  useEffect(() => {
    if (routeIn && dosage === null) {
  
      setDosageFocus(false);
      setTimeout(() => {
        setDosageFocus(true);
      }, 100)
    }
    if (routeIn && (routeOut === null) && isMobile) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [routeIn]) 

  useEffect(() => {
    if (routeOut && dosage !== '') {
      setStepper(4);
      let selection = molecules.filter(molecule => molecule.fromMed === moleculeIn.name && molecule.toMed === moleculeOut.name && molecule.fromType === routeIn && molecule.toType === routeOut);
      setSelection(selection);
    }
    if (routeOut && isMobile) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [routeOut]) 

  useEffect(() => {
    if (moleculeOut && routeOut) {
      setRouteOut(null);
      setStepper(3);
    }
  }, [moleculeOut])

  useEffect(() => {
    if (stepper === 5) {
      // scroll to top
      window.scrollTo({
        top: 0,
      });
    }
    if (stepper === 4 && selection[0].warningSelect !== '' && dosage < selection[0].toUnitMaxLimit) {
      displayWarningSelect(selection, null);
    }
    if (stepper === 4 && (dosage * 1  > selection[0].toUnitMaxLimit * 1)) {
      displayWarningSelect(selection, true);
    }
  }, [stepper])

  useEffect(() => {
    if (moleculeIn && routeIn && dosage === null) {
      let moleculeInUnit = getUnit(molecules, moleculeIn);
      setUnit(moleculeInUnit);
      setStepper(2);
    }
    if (moleculeIn && routeIn) {
      updateMoleculesListOut(moleculeIn, routeIn);
    }
    if (moleculeIn && dosage) {
      let moleculeInUnit = getUnit(molecules, moleculeIn);
      setUnit(moleculeInUnit);
    }
  }, [moleculeIn, routeIn]);

  useEffect(() => {
    if(dosage && moleculeIn && routeIn) {
      setStepper(3);
    }
    if (moleculeIn && routeIn && moleculeOut && routeOut && dosage === '') {
      setStepper(2);
    }
    if (dosage && moleculeIn && routeIn && moleculeOut && routeOut) {
      setStepper(4);
    }
  }, [dosage]);

  const displayWarningSelect = (selection, maxLimit) => {
    let content;
    if (!maxLimit) {
      content = currentLang === 'fr' ? selection[0].warningSelect : selection[0].warningSelect_EN;
    } else {
      content = currentLang === 'fr' ? `Le dosage maximum pour cette molecule est de : ${selection[0].toUnitMaxLimit} ${unit}` : `The maximum dosage for this molecule is : ${selection[0].toUnitMaxLimit} ${unit}`;
    }
    setModalTitle(t('warningSelectTitle'));
    setModalContent(content);
    modalWarning.current.open();
  }

  const manageConnection = () => {
    const approveStale = localStorage.getItem('approve-stale-state');
    if (isOnline) {
      localStorage.removeItem('approve-stale-state');
    }
    if (!isOnline) {
      if (approveStale !== 'true') {
        setModalTitle(modalContents().offline.title[currentLang]);
        setModalContent(modalContents().offline.content[currentLang]);
        modal.current.open()
        const dataPresent  = localStorage.getItem('data-present');
        if (dataPresent) {
          localStorage.setItem('approve-stale-state', true);
        } 
      }
    }
  }

  const getMolecules = async () => {
    const molecules = await getMoleculesWithRates();
    // Display error message if data is not valid array
    const sortedMolecules = molecules.sort((a, b) => a.id - b.id);
    setMolecules(sortedMolecules);
    setMoleculesListIn(sortedMolecules);
    storeMolecules(sortedMolecules); 
    localStorage.setItem('data-present', true)
  };

  const storeMolecules = (molecules) => {
    localStorage.setItem('molecules', JSON.stringify(molecules));
    let date = new Date();
    localStorage.setItem('moleculesDate', date);
  }

  const checkDataExpiration = () => {
    let moleculesDate = new Date(localStorage.getItem('moleculesDate'));
    let date = new Date();
    let diff = date - moleculesDate; 
    let diffHours = Math.ceil(diff / (1000 * 60 * 60)) - 1;
    if (diffHours > 8) { 
      localStorage.removeItem('approve-stale-state');
      getMolecules();
    }
  }

  const updateMoleculesListOut = (molecule, route) => {
    const ListOut = molecules.filter(m => m.fromMed === molecule.name && m.fromType === route);
    setMoleculesListOut(ListOut);
  }

  const convert = (e) => {
    e.preventDefault();
    const result = getMatchingConversionInfos(molecules, moleculeIn, routeIn, moleculeOut, routeOut, dosage);
    setResult(result);
    setStepper(5);
  };

  const resetConverter = () => {
    setMoleculeIn(null);
    setMoleculeOut(null);
    setMoleculesListOut([]);
    setStepper(1);
    setRouteIn(null);
    setUnit(null);
    setDosage(null);
    setRouteOut(null);
    setDosageType(null);
    setResult(null);
    setDosageFocus(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  const manageResize = () => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }

  return (
    <MainContent>
      <Modal ref={modal} title={modalTitle} validate={t('close')}>
        {modalContent}
      </Modal>
      <Modal ref={modalWarning} title={modalTitle} validate={t('understand')}>
        {modalContent}
      </Modal>
      {
        isLoading &&
        <Loader />
      }
      {
        molecules && !isLoading &&
        <ContentWrapper>
        {
          (stepper !== 5 || !isMobile) &&
          <Form 
            onSubmit={(e) => convert(e)}
            className={stepper === 5 ? 'sided' : 'full'}
          >
            <FormCol className={(stepper > 0 ? 'active' : 'inactive')}>
              <SelectBox
                step={1}
                title={t('moleculeIn')}
                options={moleculesListIn}
                setSelected={setMoleculeIn}
                setRoute={setRouteIn}
                context='in'
              />
            </FormCol>

            <FormCol className={(stepper > 1 ? 'active' : 'inactive')}>
              <InputBox
                step={2}
                title={t('dosage')}
                unit={unit}
                setDosage={setDosage}
                focus={dosageFocus}
              />
            </FormCol>

            <FormCol className={(stepper > 2 ? 'active' : 'inactive')}>
              <SelectBox
                step={3}
                title={t('moleculeOut')}
                options={moleculesListOut}
                setSelected={setMoleculeOut}
                setRoute={setRouteOut}
                context='out'
              />
              {((stepper > 3) && (currentLang === 'fr' ? ((selection[0].warningSelect.length) > 0) :  ((selection[0].warningSelect_EN.length) > 0 ))) && 
             <Bloc
              bgColor='#FFFFFF'
              color='#B33C36'
              boxShadow>
              <h3>{t('warning')}</h3>
              <p>{ currentLang === 'fr' ? selection[0].warningSelect : selection[0].warningSelect_EN}</p>
            </Bloc>
            }

            </FormCol>
        {
          ((stepper !== 5 && !isMobile) || (stepper === 4 && isMobile)) &&
          <FormCol className={stepper > 3 ? 'active' : 'inactive'}>
            <ButtonWrapper>
              <ButtonContent>
                <Button
                  label={t('convert')}
                  type="submit"
                  bgColor="#E49806"
                  color="#FFFFFF"
                />
              </ButtonContent>
            </ButtonWrapper>
          </FormCol>
        }
          </Form>
        }
        {
          stepper === 5 && result &&
          <Result>
            <BlocsWrapper>
              <Bloc
                bgColor='#FFFFFF'
                color='#045F61'
                boxShadow>
                <h3>{t('moleculeIn')}</h3>
                <Summary
                  result={result}
                />
              </Bloc>
              <Bloc 
                bgColor='#FFFFFF'
                color='#045F61'
                boxShadow>
                <h3>{t('result')}</h3>
                <h4>{result.toMed.toLowerCase()} {result.toType}</h4>
                <p className='big'>
                  {result.conversionResult}
                  <span>{result.toUnit}</span>
                </p> 
              </Bloc>
                {
                  result.warningAfterSelect &&
                  <Bloc
                    bgColor='#FFFFFF'
                    color='#B33C36'
                    boxShadow>
                    <h3>{t('warning')}</h3>
                    <p>{result.warningAfterSelect}</p>
                  </Bloc>
                }
            </BlocsWrapper>

            <ButtonWrapper>
              <ButtonContent onClick={() => resetConverter()}>
                <Button
                  label={t('newConversion')}
                  bgColor="#E49806"
                  color="#FFFFFF"
                />
              </ButtonContent>
            </ButtonWrapper>
          </Result>
        }
        </ContentWrapper> 
      }
    </MainContent>
  )
}


export default Calculator