import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getTranslations } from './services/translations';
import { transformTranslations } from './helpers/transformTranslations';

const getTransAndInitialize = async () => {
  const apiTranslations = await getTranslations();
  const translations = transformTranslations(apiTranslations);

  i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true, 
    lng: 'fr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: translations
  });
};

getTransAndInitialize();


export default i18n;