import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const lngs = {
  fr: { nativeName: 'Français' },
  en: { nativeName: 'English' }
};

const SwitcherWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  button {
    padding: 10px;
    width: 100%;
    margin: 5px 0;
    height: 42px;
    border-radius: 8px;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    background-color: #FFFFFF;
    appearance: none;
    border: none;
    color: rgb(12 37 102);
    font-size: 16px;
    cursor: pointer;
    &.active {
      color: #ffffff;
      background-color: #274EA3; 
    }
  }
`;

const LangSwitcher = () => {

  const { t, i18n } = useTranslation();

  return (
      <SwitcherWrapper>
        {Object.keys(lngs).map((lng) => (
          <button key={lng} className={i18n.resolvedLanguage === lng ? 'active' : '' } type="submit" onClick={() => i18n.changeLanguage(lng)}>
            {lngs[lng].nativeName}
          </button>
        ))}
      </SwitcherWrapper>
  )
}

export default LangSwitcher