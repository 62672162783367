import React from 'react';
import spinner from '../../images/loading-spinner.svg';
import styled from 'styled-components';


export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 98vh;

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg); 
    }
    100% { 
      transform: rotate(360deg); 
    }
  };
`;

const LoaderImg = styled.img`
  animation: loading-spinner 1s linear infinite;
  width: 60px;
  margin-bottom: 15vh;
  @media screen and (max-width: 768px) {
    margin-bottom: 40vh;
  }
  display: block;
`;

const Loader = () => {
  return (
    <LoaderWrapper>
      <LoaderImg src={spinner} alt="loading" />
    </LoaderWrapper>
  )
}

export default Loader