import React from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import { ReactComponent as AboutIcon } from '../../images/menu.svg';
import { ReactComponent as CalcIcon } from '../../images/calc.svg';
import { ReactComponent as ConfigIcon } from '../../images/cog.svg';

import { useTranslation } from 'react-i18next';

const NavWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 18px;
  width: 100%;
  z-index: 100;
  @media (min-width: 900px) {
    position: fixed;
    left: 0;
    top: 5px;
    width: 60%;
    z-index: 100;
    height: 72px;
  }
`;

const Nav = styled.nav`
  background-color: #fff;
  border-radius: 8px;
  height: 72px;
  display: flex;
  align-items: center;
  margin: 0 25px;
  padding: 0 5px;
  justify-content: space-around;
  box-shadow: 0 0 8px 0 rgb(4 95 97 / 19%);
  font-family: 'Roboto', sans-serif;
  @media (min-width: 900px) {
    justify-content: start;
    box-shadow: none;
    height: 70px;
  }
  a {
    width: 98px;
    height: 54px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    svg {
      #Symbols g {
        fill: #367E80;
      }
      #Symbols g#cog {
        fill: #367E80;
        stroke: #367E80;
      }
    }
    .nav-label {
      display: none;
    }
    &.active {
      background-color: #E49806;
      .nav-label {
        color: #FFFFFF;
      }
      svg {
        #Symbols g {
          fill: #ffffff;
        }
        #Symbols g#cog {
        fill: #ffffff;
        stroke: #ffffff;
      }
      }

    }
    @media (min-width: 900px) {
      width: auto;
      padding: 0 15px;
      margin-right: 20px;
      .nav-label {
        display: block;
        font-size: 20px;
        text-decoration: none;
        color: #045F61;
        margin-left: 10px;
      }
    }
  }
`;

const NavBar = () => {
  const { t } = useTranslation();
  return (
    <NavWrapper>
      <Nav>
        <NavLink className={({ isActive }) => isActive ? "link active" : "link"} to="/about"><AboutIcon/><span className="nav-label">{t('infos')}</span></NavLink>
        <NavLink className={({ isActive }) => isActive ? "link active" : "link"} to="/"><CalcIcon/><span className="nav-label">{t('calc')}</span></NavLink>
        <NavLink className={({ isActive }) => isActive ? "link active" : "link"} to="/settings"><ConfigIcon/><span className="nav-label">{t('parameters')}</span></NavLink>
      </Nav>
    </NavWrapper>  
  )
}

export default NavBar