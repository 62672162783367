import React from 'react';
import styled from 'styled-components';

const SummaryContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-top: 10px;
  justify-content: space-between;
`;

const SummaryIn = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  span {
    text-transform: capitalize;
  }
`;

const Summary = ({result}) => {
  console.log(result);
  return (
    <SummaryContent>
      <SummaryIn>
        <span>{ result.fromMed.toLowerCase() }</span> {result.fromType}
        <div>{result.conversionDosage} {result.fromUnit}</div>
      </SummaryIn>
    </SummaryContent>
  )
}

export default Summary