export const getMoleculesNames = (moleculesList, context) => {
  let moleculesTemp = moleculesList.map(item => {
    return context === 'in' ? item.fromMed : item.toMed;
  })
  let molecules = [...new Set(moleculesTemp)];
  return molecules;
}

export const getMoleculeRoutes = (moleculeName, molecules, context) => {
  const MoleculeRoutes = context === 'in' ? molecules.filter(molecule => molecule.fromMed === moleculeName) : molecules.filter(molecule => molecule.toMed === moleculeName);
  const tempRoutes = context === 'in' ? MoleculeRoutes.map(item => item.fromType) : MoleculeRoutes.map(item => item.toType);
  let routes = [...new Set(tempRoutes)];
  return routes;
}