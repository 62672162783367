import styled from "styled-components";

export const ContentWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 20vh;
  padding: 20px 0 20px 20px;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }
`;

export const ContentBloc = styled.div`
  @media screen and (min-width: 900px){
    margin-right: 20px;
    width: 50%;
  }
`;