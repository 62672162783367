import React from 'react';
import Bloc from '../components/Blocs/Bloc';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ContentBloc } from '../styles/common';
import styled from 'styled-components';
import sfap from '../images/organizations/sfap.png';
import afsos from '../images/organizations/afsos.png';
import sfetd from '../images/organizations/sfetd.png';

const PartnersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  a {
    margin: 0 10px;
    display: block;
    img {
      height: auto;
      width: 90px;
      mix-blend-mode: multiply;
    }
  }
  `;

const About = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <ContentBloc>
        <Bloc
          bgColor='#E5F8F4'
          color='#045F61'
          textSize='normal'>
          <h3>{t('aboutLeftTitle')}</h3>
          <PartnersWrapper>
            <a href="https://sfap.org/" target="_blank"><img src={sfap}/></a>
            <a href="https://www.afsos.org/?lang=fr" target="_blank"><img src={afsos}/></a>
            <a href="https://www.sfetd-douleur.org/" target="_blank"><img src={sfetd}/></a>
          </PartnersWrapper>
        </Bloc>
      </ContentBloc>
      <ContentBloc>
        <Bloc
          bgColor='#E5F8F4'
          color='#045F61'
          textSize='normal'>
          <h3>{t('aboutRightTitle')}</h3>
          <p>{t('aboutRightText')}</p>
        </Bloc>
      </ContentBloc>
    </ContentWrapper>
  )
}

export default About